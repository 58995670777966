import {type Theme} from '@mui/material/styles';

export const inputStyles = {
  root: (fullWidth: boolean, inline: boolean) => ({
    my: inline ? 0 : 0.5,
    display: 'grid',
    width: fullWidth ? '100%' : 'auto',
    alignSelf: 'baseline',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateAreas: `'. label .'
      'start-slot input end-slot'
      '. error .'`,
  }),
  inputArea: {
    gridArea: 'input',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  labelArea: {
    gridArea: 'label',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
  startSlotArea: {
    gridArea: 'start-slot',
    alignSelf: 'center',
    mr: 1,
  },
  endSlotArea: {
    gridArea: 'end-slot',
    alignSelf: 'center',
    ml: 1,
  },
  errorArea: (hasError: boolean) => ({
    gridArea: 'error',
    height: 10,
    mt: 0.25,
    mb: 0.5,
    color: hasError ? 'error.main' : 'text.secondary',
  }),
  input: {
    py: 0.25,
    borderRadius: 1,
    backgroundColor: 'background.paper',
    minWidth: 100,
    minHeight: 42,

    // Controls border styling on input
    '> fieldset': {
      borderColor: 'divider',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'end',
    mb: 0.5,
  },
  selectWithoutExpandIcon: {
    '[role="button"]': {
      p: '4px !important',
    },
  },
  inlineSelect: {
    p: 0,
    backgroundColor: 'transparent',
    width: 'fit-content',
    minWidth: 'auto',
    minHeight: 'auto',

    '&:focus-within': {
      ml: -1,
      pl: 1,
      outline: '1px solid',
      outlineColor: 'primary.main',
    },

    '& > fieldset': {
      pl: 0,
      border: 'none',
    },

    '& svg': {
      color: 'primary.main',
    },

    // Do not grey-out text in disabled state
    '& p': {
      textFillColor: (theme: Theme) => theme.palette.text.primary,
    },
  },
  inlineSelectInput: {
    py: 0,
    pl: 0,
  },
};

export const menuStyles = {
  menu: {
    mt: 0.5,
    backgroundColor: 'background.paper',
    borderRadius: 1.5,
    ul: {
      py: 0.5,
      maxHeight: 500,
    },
    li: {
      gap: 1,
      borderRadius: 1,
      mx: 0.5,
      pr: 0.5,
      pl: 2,
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      },
      '&:hover': {
        backgroundColor: 'action.hover',
      },
    },
  },
  menuIcon: {
    mr: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuCheckBox: {
    p: 0,
    ml: -1,
    color: 'primary.main',
  },
  menuCheckmark: {
    ml: 'auto',
    color: 'primary.main',
  },
};
