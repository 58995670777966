import {Popup} from '@carbon/icons-react';
import {Box, IconButton, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AccentColors} from '../../../styles/colors';
import {getAccentColor} from '../../../styles/utils';
import {type Database} from '../../../types';
import DatadogAPMButton from '../../Buttons/DatadogAPMButton';
import DatadogButton from '../../Buttons/DatadogButton';
import StyledChip from '../../StyledComponents/StyledChip';
import InfoBox from '../../StyledComponents/StyledInfoBox';
import StyledModal from '../../StyledComponents/StyledModal';
import DatabaseCardLarge from './DatabaseCardLarge';

function DatabaseCardSmall(database: Database) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const isServerless = database.capacity_mode === 'serverless';

  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid size={9}>
          <Typography
            variant="h5"
            mb={2}
            onClick={() => {
              navigate(`./${database?.index_name}`);
            }}
          >
            {database?.index_name}
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={3}>
          <DatadogAPMButton {...database} />
          <DatadogButton {...database} size={20} />
          <IconButton onClick={() => setOpen(true)}>
            <Popup size="20" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid size={2}>
          <InfoBox size="small" label="Status">
            <StyledChip
              size="small"
              label={database?.status}
              color={database?.status === 'Ready' ? AccentColors.GREEN : AccentColors.RED}
            />
          </InfoBox>
        </Grid>
        {!isServerless && (
          <Grid size={2}>
            <InfoBox label="Pod Type">
              <StyledChip
                size="small"
                label={database?.pod_type}
                color={getAccentColor(database?.pod_type)}
              />
            </InfoBox>
          </Grid>
        )}
        <Grid size={2}>
          <InfoBox size="small" label="Dimensions" text={database?.dimension} />
        </Grid>
        <Grid size={2}>
          <InfoBox size="small" label="Capacity Mode" text={database?.capacity_mode} />
        </Grid>
        <Grid size={2}>
          {isServerless ? (
            <InfoBox size="small" label="Region" text={database?.region} />
          ) : (
            <InfoBox size="small" label="Replicas" text={database?.replicas} />
          )}
        </Grid>
        <Grid size={2}>
          {isServerless ? (
            <InfoBox size="small" label="Cloud" text={database?.cloud} />
          ) : (
            <InfoBox size="small" label="Shards" text={database?.shards} />
          )}
        </Grid>
      </Grid>
      {/* Large Card Modal */}
      <StyledModal
        open={open}
        customId={`${database.index_name}-modal`}
        closeModal={() => setOpen(false)}
      >
        <DatabaseCardLarge {...database} />
      </StyledModal>
    </Box>
  );
}
export default DatabaseCardSmall;
