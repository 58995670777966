function generateShadow(
  hOffset: number,
  vOffset: number,
  blur: number,
  spread: number,
  color: string,
) {
  return `${hOffset}px ${vOffset}px ${blur}px ${spread}px ${color}`;
}

function createPineconeShadow(intensity: number, theme: 'light' | 'dark') {
  if (theme === 'dark') {
    return generateShadow(
      /* hOffset = */ 1,
      /* vOffset = */ intensity,
      /* blur = */ intensity * 2,
      /* spread = */ intensity / 2,
      /* color = */ `rgba(0,0,0, ${intensity / 20})`,
    );
  }
  return generateShadow(
    /* hOffset = */ 1,
    /* vOffset = */ intensity,
    /* blur = */ intensity * 2,
    /* spread = */ intensity / 2,
    /* color = */ `rgba(163, 169, 175, ${intensity / 50})`,
  );
}

const PineconeShadows: Record<string, any> = {
  LIGHT: [
    'none', // 0
    createPineconeShadow(1, 'light'), // 1
    createPineconeShadow(2, 'light'), // 2
    createPineconeShadow(3, 'light'), // 3
    createPineconeShadow(4, 'light'), // 4
    createPineconeShadow(5, 'light'), // 5
    createPineconeShadow(6, 'light'), // 6
    createPineconeShadow(7, 'light'), // 7
    createPineconeShadow(8, 'light'), // 8
    createPineconeShadow(9, 'light'), // 9
    createPineconeShadow(10, 'light'), // 10
    createPineconeShadow(11, 'light'), // 11
    createPineconeShadow(12, 'light'), // 12
    createPineconeShadow(13, 'light'), // 13
    createPineconeShadow(14, 'light'), // 14
    createPineconeShadow(15, 'light'), // 15
    createPineconeShadow(16, 'light'), // 16
    createPineconeShadow(17, 'light'), // 17
    createPineconeShadow(18, 'light'), // 18
    createPineconeShadow(19, 'light'), // 19
    createPineconeShadow(20, 'light'), // 20
    createPineconeShadow(21, 'light'), // 21
    createPineconeShadow(22, 'light'), // 22
    createPineconeShadow(23, 'light'), // 23
    createPineconeShadow(24, 'light'), // 24
  ],
  DARK: [
    'none', // 0
    createPineconeShadow(1, 'dark'), // 1
    createPineconeShadow(2, 'dark'), // 2
    createPineconeShadow(3, 'dark'), // 3
    createPineconeShadow(4, 'dark'), // 4
    createPineconeShadow(5, 'dark'), // 5
    createPineconeShadow(6, 'dark'), // 6
    createPineconeShadow(7, 'dark'), // 7
    createPineconeShadow(8, 'dark'), // 8
    createPineconeShadow(9, 'dark'), // 9
    createPineconeShadow(10, 'dark'), // 10
    createPineconeShadow(11, 'dark'), // 11
    createPineconeShadow(12, 'dark'), // 12
    createPineconeShadow(13, 'dark'), // 13
    createPineconeShadow(14, 'dark'), // 14
    createPineconeShadow(15, 'dark'), // 15
    createPineconeShadow(16, 'dark'), // 16
    createPineconeShadow(17, 'dark'), // 17
    createPineconeShadow(18, 'dark'), // 18
    createPineconeShadow(19, 'dark'), // 19
    createPineconeShadow(20, 'dark'), // 20
    createPineconeShadow(21, 'dark'), // 21
    createPineconeShadow(22, 'dark'), // 22
    createPineconeShadow(23, 'dark'), // 23
    createPineconeShadow(24, 'dark'), // 24
  ],
};

export default PineconeShadows;
