import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '../../Buttons/IconButton';

interface ModalHeaderProps {
  title: React.ReactNode;
  subtitle?: string | React.ReactNode;
  close?: () => void;
}

const styles = {
  root: {
    mb: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    width: '100%',
  },
  title: {
    width: '100%',
    minWidth: 0,
  },
  closeButton: {
    m: 0,
    color: 'text.secondary',
  },
};

export default function ModalHeader({title, subtitle, close}: ModalHeaderProps) {
  const isTitleString = typeof title === 'string';

  return (
    <Box sx={styles.root}>
      <Box sx={styles.title}>
        {isTitleString ? (
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        ) : (
          title
        )}
        {subtitle ? (
          <Typography variant="overline" fontSize={10}>
            {subtitle}
          </Typography>
        ) : null}
      </Box>
      {close ? (
        <IconButton
          id="close-modal-icon"
          ariaLabel="Close modal"
          icon={<CloseIcon />}
          onClick={close}
          color="secondary"
          sx={styles.closeButton}
        />
      ) : null}
    </Box>
  );
}
