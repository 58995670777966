import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {AccentColors} from '../../../styles/colors';
import {type Collection} from '../../../types';
import StyledChip from '../../StyledComponents/StyledChip';
import InfoBox from '../../StyledComponents/StyledInfoBox';

function CollectionCard(collection: Collection) {
  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid>
          <Typography variant="h5">{collection?.name}</Typography>
        </Grid>
        <Grid>
          <Typography variant="subtitle2">
            ID: <span style={{fontStyle: 'italic'}}> {collection?.id} </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid size={3}>
          <InfoBox size="small" label="Status">
            <StyledChip
              size="small"
              label={collection?.status}
              color={collection?.status === 'Ready' ? AccentColors.GREEN : AccentColors.RED}
            />
          </InfoBox>
        </Grid>
        <Grid size={3}>
          <InfoBox
            size="small"
            label="Size"
            text={`${((collection?.size || 0) * 1e-6).toFixed(2)} mb`}
          />
        </Grid>
        <Grid size={3}>
          <InfoBox size="small" label="Dimensions" text={collection?.dimension} />
        </Grid>
        <Grid size={3}>
          <InfoBox size="small" label="Record Count" text={collection?.vectorcount} />
        </Grid>
        <Grid size={3}>
          <InfoBox size="small" label="Source Index" text={collection?.source} />
        </Grid>
        <Grid size={3}>
          <InfoBox size="small" label="Environment" text={collection?.environment} />
        </Grid>
        <Grid size={3}>
          <InfoBox size="small" label="Created at" text={collection?.created_on} />
        </Grid>
        <Grid size={3}>
          <InfoBox
            size="small"
            label="Deleted at"
            text={
              collection?.deleted_on === '1970-01-01T00:00:00.000Z'
                ? 'Active'
                : collection?.deleted_on
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default CollectionCard;
