import {CaretDown} from '@carbon/icons-react';
import {Accordion, AccordionSummary, Box, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {AccentColors} from '../../../styles/colors';
import {getAccentColor} from '../../../styles/utils';
import {type Database} from '../../../types';
import DatadogAPMButton from '../../Buttons/DatadogAPMButton';
import DatadogButton from '../../Buttons/DatadogButton';
import StyledChip from '../../StyledComponents/StyledChip';
import InfoBox from '../../StyledComponents/StyledInfoBox';

function DatabaseCardLarge(database: Database) {
  const isServerless = database.capacity_mode === 'serverless';
  return (
    <Box>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid size={10}>
          <Typography variant="h2" mb={4}>
            {database?.index_name}
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={1.8}>
          <DatadogAPMButton {...database} />
          <DatadogButton {...database} size={40} />
        </Grid>
      </Grid>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid size={2}>
          <InfoBox size="small" label="Status">
            <StyledChip
              size="small"
              label={database?.status}
              color={database?.status === 'Ready' ? AccentColors.GREEN : AccentColors.RED}
            />
          </InfoBox>
        </Grid>
        <Grid size={4}>
          <InfoBox size="small" label="Host" text={database?.host} />
        </Grid>
        <Grid size={4}>
          <InfoBox size="small" label="Dimensions" text={database?.dimension} />
        </Grid>
        <Grid size={2}>
          <InfoBox label="Metric">
            <StyledChip
              size="small"
              label={database?.metric}
              color={getAccentColor(database?.metric)}
            />
          </InfoBox>
        </Grid>
        <Grid size={2}>
          <InfoBox label="Pod Type">
            <StyledChip
              size="small"
              label={database?.pod_type}
              color={getAccentColor(database?.pod_type)}
            />
          </InfoBox>
        </Grid>
        <Grid size={2}>
          <InfoBox size="small" label="Capacity Mode" text={database?.capacity_mode} />
        </Grid>
        <Grid size={2}>
          {isServerless ? (
            <InfoBox size="small" label="Region" text={database?.region} />
          ) : (
            <InfoBox size="small" label="Replicas" text={database?.replicas} />
          )}
        </Grid>
        <Grid size={2}>
          {isServerless ? (
            <InfoBox size="small" label="Cloud" text={database?.cloud} />
          ) : (
            <InfoBox size="small" label="Shards" text={database?.shards} />
          )}
        </Grid>
      </Grid>
      <Accordion sx={{my: 3}}>
        <AccordionSummary expandIcon={<CaretDown />} aria-controls="crd" id="crd">
          <Typography variant="h4">View CRD</Typography>
        </AccordionSummary>
        <Box sx={{p: 3, maxHeight: 600, overflow: 'auto'}}>
          <pre>{JSON.stringify(database, null, 2)}</pre>
        </Box>
      </Accordion>
    </Box>
  );
}
export default DatabaseCardLarge;
