import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {useOrganizationsByUserId} from '../../../hooks/selectors';
import {type User} from '../../../types';
import UserOrganizationCard from './UserOrganizationCard';

function UserOrganizationCardList(user: User) {
  const organizations = useOrganizationsByUserId(user.id);

  return (
    <Box>
      <Typography variant="h2" mb={2}>
        Organizations
      </Typography>
      <Grid container direction="column" spacing={2}>
        {organizations.map((organization) => (
          <Grid key={organization.id} size={12}>
            <UserOrganizationCard
              organization={organization}
              user={user}
              isSingleOrg={organizations.length === 1}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default UserOrganizationCardList;
