import Box, {type BoxProps} from '@mui/material/Box';
import Button, {type ButtonProps} from '@mui/material/Button';
import {captureTelemetryEvent} from '@pinecone-experience/commons/telemetry';
import * as React from 'react';
import Tooltip from '../Tooltip';
import {type CommonButtonProps} from './Utils/types';

const styles = {
  root: {
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    borderColor: 'divider',
  },
};

export interface IconButtonProps extends CommonButtonProps {
  icon: React.ReactElement;
  color?: ButtonProps['color'];
  onClick?: (evt: React.MouseEvent) => void;
  shape?: 'circle' | 'squircle';
  border?: boolean;
  disabled?: boolean;
  href?: string;
  dataTestId?: string;
  dataHeap?: string;
  ariaLabel?: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  ariaHaspopup?: boolean;
  tabIndex?: number;
  type?: ButtonProps['type'];
  sx?: Required<BoxProps['sx']>;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      id,
      telemetry,
      color,
      onClick,
      icon,
      tooltip,
      shape = 'squircle',
      border,
      disabled,
      href,
      dataHeap,
      ariaLabel,
      ariaControls,
      ariaExpanded,
      ariaHaspopup,
      dataTestId,
      tabIndex,
      type,
      sx = {},
    },
    ref,
  ) => {
    const handleClick = (evt: React.MouseEvent) => {
      if (telemetry) {
        captureTelemetryEvent(telemetry.event, telemetry.properties);
      }
      evt.stopPropagation();
      if (onClick) {
        onClick(evt);
      }
    };

    return (
      <Box component="span" m={1} sx={sx}>
        <Tooltip title={tooltip}>
          <Button
            type={type}
            id={id}
            ref={ref}
            tabIndex={tabIndex}
            variant={border ? 'outlined' : 'text'}
            color={color}
            sx={[
              styles.root,
              {borderRadius: shape === 'squircle' ? 2 : '100%'},
              {fill: disabled ? 'action.disabled' : ''},
            ]}
            onClick={handleClick}
            href={href}
            disabled={disabled}
            data-testid={dataTestId || id}
            data-heap={dataHeap}
            aria-label={ariaLabel}
            aria-controls={ariaControls}
            aria-expanded={ariaExpanded}
            aria-haspopup={ariaHaspopup}
          >
            {icon}
          </Button>
        </Tooltip>
      </Box>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
