import posthog from 'posthog-js';

const isProduction = import.meta.env.PROD;

export const POSTHOG_EVENTS = {
  SIGNUP: 'user_signed_up',
  LOGOUT: 'user_logged_out',
};

export function captureTelemetryEvent(event: string, properties = {}) {
  try {
    if (isProduction) {
      posthog.capture(event, properties);
    } else {
      // eslint-disable-next-line no-console
      console.log('[DEV] Sending event to posthog: ', event, properties);
    }
  } catch (e) {
    // ignore
  }
}

const key = 'pc_telemetry_events';
export function captureTelemetryEventOnce(event: string, properties = {}) {
  try {
    const storedEvents = JSON.parse(window.localStorage.getItem(key) || '{}');
    if (!storedEvents[event]) {
      storedEvents[event] = true;
      window.localStorage.setItem(key, JSON.stringify(storedEvents));
      captureTelemetryEvent(event, properties);
    }
  } catch (e) {
    // ignore
  }
}
