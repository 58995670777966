import {type PopperProps} from '@mui/material/Popper';
import MuiTooltip, {type TooltipProps as MuiTooltipProps} from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type * as React from 'react';

interface TooltipProps {
  title?: React.ReactNode;
  children: React.ReactElement;
  followCursor?: boolean;
  placement?: MuiTooltipProps['placement'];
  sx?: PopperProps['sx'];
  leaveDelay?: number;
  wrapText?: boolean;
  disablePortal?: boolean;
}

const styles = {
  tooltip: {
    p: 1.2,
    whiteSpace: 'pre-line',
    backgroundColor: 'background.paper',
    color: 'text.primary',
    boxShadow: 8,
  },
  noWrapTooltip: {
    maxWidth: 'none',
  },
  arrow: {
    color: 'background.paper',
  },
};

function Tooltip({
  title,
  children,
  followCursor,
  placement = 'top',
  sx = {},
  leaveDelay,
  wrapText = true,
  disablePortal,
}: TooltipProps) {
  if (title) {
    return (
      <MuiTooltip
        arrow
        title={<Typography variant="body2">{title}</Typography>}
        followCursor={followCursor}
        placement={placement}
        leaveDelay={leaveDelay}
        slotProps={{
          popper: {sx, disablePortal},
          tooltip: {
            sx: [styles.tooltip, !wrapText && styles.noWrapTooltip],
          },
          arrow: {sx: styles.arrow},
        }}
      >
        {/* span is needed for disabling buttons with tooltips, see https://mui.com/material-ui/react-tooltip/#disabled-elements */}
        {/* span can also hold a ref to which the tooltip will need to anchor itself,
          whereas a child component passed in may not, see https://mui.com/material-ui/guides/composition/#caveat-with-refs */}
        <span>{children}</span>
      </MuiTooltip>
    );
  }
  return children;
}

export default Tooltip;
