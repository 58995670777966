import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {PaymentPlanColors, PaymentStatusColors, SupportTierColors} from '../../../styles/colors';
import {type Organization} from '../../../types';
import ChargeDeskButton from '../../Buttons/ChargeDeskButton';
import GrafanaButton from '../../Buttons/GrafanaButton';
import OrbButton from '../../Buttons/OrbButton';
import StripeButton from '../../Buttons/StripeButton';
import StyledChip from '../../StyledComponents/StyledChip';
import StyledDateFormatting from '../../StyledComponents/StyledDateFormatting';
import InfoBox from '../../StyledComponents/StyledInfoBox';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

function OrganizationCardLarge(organization: Organization) {
  const {
    id,
    name,
    plan,
    support_tier,
    payment_status: paymentStatus,
    stripe_id: stripeId,
    created_at,
    updated_at,
    aws_marketplace_id: awsMarketplaceId,
    azure_marketplace_id: azureMarketplaceId,
    gcp_marketplace_id: gcpMarketplaceId,
  } = organization;

  let paymentMethod = '';
  if (gcpMarketplaceId) {
    paymentMethod = 'GCP';
  } else if (awsMarketplaceId) {
    paymentMethod = 'AWS';
  } else if (azureMarketplaceId) {
    paymentMethod = 'Azure';
  } else if (stripeId) {
    paymentMethod = 'Stripe';
  } else {
    paymentMethod = 'None Found';
  }

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid size={8}>
          <StyledTitleLink name={name} id={id} nameColor="white" nameFontSize="h2" />
        </Grid>
        <Grid container justifyContent="flex-end" mb={3} size={4}>
          <ChargeDeskButton {...organization} />
          <StripeButton {...organization} />
          <OrbButton {...organization} />
          <GrafanaButton {...organization} />
        </Grid>
      </Grid>
      <Grid container columnSpacing={4}>
        <Grid size={2}>
          <InfoBox label="Plan">
            <StyledChip
              size="small"
              label={plan === 'free' ? 'starter' : plan}
              color={PaymentPlanColors[plan]}
            />
          </InfoBox>
        </Grid>
        <Grid size={2}>
          <InfoBox label="Support Tier">
            <StyledChip
              size="small"
              label={support_tier || 'Not found'}
              color={SupportTierColors[support_tier || 'free']}
            />
          </InfoBox>
        </Grid>
        <Grid size={2}>
          <InfoBox label="Payment Status">
            <StyledChip
              size="small"
              label={paymentStatus}
              color={PaymentStatusColors[paymentStatus]}
            />
          </InfoBox>
        </Grid>
        <Grid size={6}>
          <StyledDateFormatting created_at={created_at} updated_at={updated_at} />
        </Grid>
        <Grid size={2}>
          <InfoBox label="Payment Method" text={paymentMethod} />
        </Grid>
        <Grid size={4}>
          <InfoBox
            label={`${paymentMethod} ID`}
            text={stripeId || awsMarketplaceId || azureMarketplaceId || gcpMarketplaceId || 'N/A'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default OrganizationCardLarge;
