import {type QuotaKeys, type QuotaLimit} from '@pinecone-experience/commons/quotas';
import {DASHBOARD_BASE_URL} from '../constants';
import {
  type Collection,
  type Database,
  type Organization,
  type OrganizationMember,
  type Project,
  type ProjectMember,
  type User,
  type UserMember,
} from '../types';

export default class DashboardApi {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  fetch<T>(url: string, options = {}) {
    return fetch(`${DASHBOARD_BASE_URL}/admin/${url}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((data) => {
          throw new Error(data.message || data.error);
        });
      }
      return res.json() as Promise<T>;
    });
  }

  get<T>(url: string, params = {}, options = {}) {
    return this.fetch<T>(`${url}?${new URLSearchParams(params)}`, options);
  }

  post<T>(url: string, params = {}) {
    const options = {
      method: 'POST',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  delete<T>(url: string, params = {}) {
    const options = {
      method: 'DELETE',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  patch<T>(url: string, params = {}) {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  getOrganizationInfo(organizationId: string) {
    return this.get<{
      organization: Organization;
      projects: Project[];
      users: UserMember[];
    }>(`organizations/${organizationId}`);
  }

  patchUserOrgRole(firebaseId: string, organizationId: string, role: string) {
    return this.post(`organizations/${organizationId}/user/${firebaseId}`, {role});
  }

  patchProjectOrganization(
    organizationId: string,
    projectId: string,
    targetOrganizationId: string,
  ) {
    return this.post(`organizations/${organizationId}/projects/${projectId}`, {
      targetOrganizationId,
    });
  }

  getProjectInfo(projectId: string) {
    return this.get<{project: Project; users: UserMember[]}>(`projects/${projectId}`);
  }

  getProjectServices(projectId: string) {
    return this.get<{databases: Database[]; collections: Collection[]}>(
      `projects/${projectId}/services`,
    );
  }

  getUserInfo(userId: string) {
    return this.get<{
      user: User;
      orgs: OrganizationMember[];
      projects: ProjectMember[];
    }>(`users/${userId}`);
  }

  searchUsersByEmail(email: string) {
    return this.get<{users: User[]}>(`users-by-email/${email}`);
  }

  searchUsers(query: string) {
    return this.get<{users: User[]}>(`users/`, {query});
  }

  searchProjects(query: string) {
    return this.get<{projects: Project[]}>(`projects/`, {query});
  }

  searchOrganizations(query: string) {
    return this.get<{organization: Organization[]}>(`organizations/`, {query});
  }

  getProjectQuotaLimits(projectId: string) {
    return this.get<{quotaLimits: Record<QuotaKeys, QuotaLimit>}>(
      `projects/${projectId}/quotas/limits`,
    );
  }

  getProjectQuotaUtilization(projectId: string) {
    return this.get<{quotaUtilization: Record<QuotaKeys, number>}>(
      `projects/${projectId}/quotas/utilization`,
    );
  }
}
